import React from "react"
import { connect } from "react-redux"
import { Layout } from "@components/layout"

import {
  JSONLD,
  Product as DataProduct,
} from "react-structured-data"


import { SimpleProduct } from "@components/ecommerce"

import { cartActions } from "@stores"


const Product = ({ pageContext, addToCart, store }) => {
  const {
    product,
  } = pageContext
  
  return (
    <Layout {...product}>
      <JSONLD>
        <DataProduct
          name={product.name}
          description={product.description}
          aggregateRating={product.reviews.averageRating}
          slogan={product.shortDescription}
        ></DataProduct>
      </JSONLD>
      <SimpleProduct
        product={product}
        addToCart={addToCart}
        cart={store.items}
      />
    </Layout>
  )

  // const modal = useRef(null)
  // const [galleryElement, setGalleryElement] = useState({})

  // const ProductType = selectProductType(product.type)
  // let _url = galleryElement.sourceUrl
  // return (
  // 	<React.Fragment>
  // 	<Modal title={'Podgląd zdjęcia'} ref={modal}
  // 		onClose={()=>{
  // 			setGalleryElement({})
  // 		}}
  // 	>
  // 		{_url && <Zoom sourceUrl={_url}/>}
  // 		{/* {galleryElement.srcSet && <ScalableImage srcSet={galleryElement.srcSet} />} */}
  // 		</Modal>
  // 	<Layout
  // 		seo={pageContext.product.seo}
  // 		siteMetaData={pageContext.product.siteMetaData}
  // 	>

  // 		<AddProductToLastSeen product={product}/>
  // 		<Inner
  // 			product={product}
  // 			nextProduct={nextProduct}
  // 			prevProduct={prevProduct}
  // 			prevCategoryProduct={prevCategoryProduct}
  // 			nextCategoryProduct={nextCategoryProduct}
  // 		>
  // 		<List>
  // 		<ProductType
  // 			product={product}
  // 			addToCart={addToCart}
  // 			resetCart={resetCart}
  // 			onGalleryClick={(element) => {
  // 				setGalleryElement(element)
  // 				modal.current.toggle()
  // 			}}
  // 		/>
  // 		</List>
  // 	</Inner>
  // 	</Layout>

  // 	</React.Fragment>
  // )
}

const mapStateToProps = (state) => ({ store: state.Cart })

const mapActionToDispatch = { ...cartActions }

export default connect(mapStateToProps, mapActionToDispatch)(Product)
